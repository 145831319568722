.App{
  display: flex;
  flex-direction: column;
}
.App-header{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.App-header-title{
  flex: 2;
  text-align: center;
}
.App-header-links{
  display: flex;
  justify-content: space-around;
  flex: 1;
}
.App-header-user{
  flex: 1;
}

.App-main{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App-main-main{
  width: 95%;
  height: 95%;
  background-color: white;
}
.App-footer {
  text-align: center;
}
.username {
  display: flex;
  align-items: center;
}
.username > p {
  margin-right: 20px;
}