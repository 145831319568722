.routeView{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
}

.routeViewData {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 3rem 200px 3rem 200px;
}

.routeViewData > h3 {
    color: #994b0b;
    margin-left: 10px;
}